<template>
  <div class="Site PageBox">
    <van-row style="margin-bottom: 10px">
      <van-col span="3">
        <img class="logo"
                :src="imgRoot+logo"
        />
      </van-col>
      <van-col span="18">
        <van-row>
          <van-col span="3" v-for="(item, index) in buttonList" :key="index" style="text-align: center" @click="clickArea">
            <img class="buttonitem" v-if="index==0"
                 :src="imgRoot+'button'+(index+1)+'_2.png'"
            />
            <img class="buttonitem" v-else
                 :src="imgRoot+'button'+(index+1)+'.png'"
            />              <div>
               <div :style="index==0?'color:#ffffff':'color:#5050B4'"> {{item['title']}}</div>
              </div>
          </van-col>
        </van-row>
      </van-col>
      <van-col span="3">
        <van-row style="text-align: center;margin-top: 10px">
          <van-col span="12">
          <div style="color: #5050b4;font-size: 16px">{{weekStr}}</div>
            <div style="color: #5050b4;font-size: 16px">{{currDate.getFullYear()}}/{{currDate.getMonth()+1}}/{{currDate.getDate()}}</div>
          </van-col>
          <van-col span="12">
            <div style="color:#ffffff;font-size: 25px">{{currTime}}</div>
          </van-col>
        </van-row>

      </van-col>
    </van-row>

    <van-row>
      <van-col span="3">
        <van-list>
          <van-cell  v-for="(item, index) in menuList" :key="index" :class="index==1?'bg2':'bg3'" @click="clickButton(index)">
            <div :style="index!=1?'color:#5050B4':''">
            <img class="menuitem"
                 :src="imgRoot+item['img']"
            />
            {{item['title']}}</div>
          </van-cell>
        </van-list>
      </van-col>
      <van-col span="18" class="bg2">
        <div class="bg3 panel">
          <van-grid :column-num="2">
            <van-grid-item v-for="value in 4" :key="value" @click="clickArea(value)">
              <img class="area" v-if="value!=3"
                   :src="imgRoot+'area'+(value)+'.png'"
              />
              <video width="450px" autoplay="autoplay" muted v-else>
                <source src="video.mp4" type="video/mp4">
                Your browser does not support the video tag
              </video>
            </van-grid-item>
          </van-grid>
        </div>
        <div class="bg3 panel" style="padding: 15px">
          <van-row  @click="clickArea">
            <van-col span="19" style="height: 40px">
              <img class="button2" v-for="value in 6" :key="value"
                   :src="imgRoot+'bb'+value+'.png'"
              />
            </van-col>
            <van-col span="5">
              <img class="button2" v-for="value in 5" :key="value"
                   :src="imgRoot+'bc'+value+'.png'"
              />
            </van-col>
          </van-row>
        </div>
      </van-col>
      <van-col span="3" class="bg2">
        <div style="margin-top:5px">
          <van-grid :column-num="3" :border="aborder" style="background-color: pink">
            <van-grid-item v-for="value in 9" :key="value" @click="clickArea">
              <div>
              <img class="arrow"
                   :src="imgRoot+'arrow'+(value)+'.png'"
              />
              </div>
            </van-grid-item>
          </van-grid>
        </div>
        <div class="bg3" style="margin: 10px">
          Speed(1-8)
          <select style="width: 60px;background-color: #1e1e3c;color: #5050B4">
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
            <option>6</option>
            <option>7</option>
            <option>8</option>
          </select>
        </div>
        <div class="bg3">
          <van-grid :column-num="3" :border="aborder">
            <van-grid-item @click="clickArea">
              <img class="arrow"
                   :src="imgRoot+'icon1.png'"
              />
            </van-grid-item>
            <van-grid-item>
              Speed
            </van-grid-item>
            <van-grid-item @click="clickArea">
              <img class="arrow"
                   :src="imgRoot+'icon2.png'"
              />
            </van-grid-item>
          </van-grid>
        </div>
        <div>
          <van-grid :column-num="3" :border="aborder">
            <van-grid-item @click="clickArea">
              <img class="arrow"
                   :src="imgRoot+'icon1.png'"
              />
            </van-grid-item>
            <van-grid-item style="color:#5050B4">
              Focus
            </van-grid-item>
            <van-grid-item @click="clickArea">
              <img class="arrow"
                   :src="imgRoot+'icon2.png'"
              />
            </van-grid-item>
          </van-grid>
        </div>
        <div>
          <van-grid :column-num="3" :border="aborder" class="bg3">
            <van-grid-item @click="clickArea">
              <img class="arrow"
                   :src="imgRoot+'icon1.png'"
              />
            </van-grid-item>
            <van-grid-item style="font-size: 13px">
              Apeture
            </van-grid-item>
            <van-grid-item @click="clickArea">
              <img class="arrow"
                   :src="imgRoot+'icon2.png'"
              />
            </van-grid-item>
          </van-grid>
        </div>
        <div class="bg3" style="margin: 10px">
          Preset point:
          <input style="width: 80px;background-color: #1e1e3c"/>
        </div>
        <div>
          <van-grid :column-num="3" :border="aborder">
            <van-grid-item @click="clickArea">
              <img class="arrow"
                   :src="imgRoot+'icon3.png'"
              />
            </van-grid-item>
            <van-grid-item @click="clickArea">
              <img class="arrow"
                   :src="imgRoot+'icon4.png'"
              />
            </van-grid-item>
            <van-grid-item @click="clickArea">
              <img class="arrow"
                   :src="imgRoot+'icon5.png'"
              />
            </van-grid-item>
          </van-grid>
        </div>
      </van-col>
    </van-row>
  </div>
</template>

<script>
  import 'vant/lib/grid-item/index.less';
import $ from "jquery";
// import {ref} from 'vue'
export default {
  name: "Home",
  inject: ["reloadHtml"],
  components: {},
  props: ["user_id"],
  data() {
    return {
      currentRate: 0,
      showNotice: false,
      currTime:'',
      currDate:'',
      imgRoot:"static/images/home/",
      logo:"Monitoringsystem.png",
      weekStr:'',
      weekDays:["Sunday", "Monday", "Tuesday", "Wensday", "Thursday", "Friday", "Saturday"],
      buttonList:[
        {"img":'',"title":"All queires"},{"img":'',"title":"Real time"},
        {"img":'',"title":"Playback"},{"img":'',"title":"Report"},{"img":'',"title":"Event"}
        ,{"img":'',"title":"System"}
      ],
      menuList:[
        {"img":'cam.png',"title":"CAM1"},{"img":'cam.png',"title":"HK1"},{"img":'cam.png',"title":"CAM2"},
      ],
      aborder:false,
      language1: localStorage.getItem("Language") == "cn",
      bannerList: "",
      moeny: "",
    };
  },

  computed: {
    text() {
      // return this.currentRate.toFixed(0) + '%'
      return "loading";
    },
  },
  watch: {

  },
  created() {
    this.currDate = new Date();
    var day = this.currDate.getDay();
    this.weekStr = this.weekDays[day];
  },
  mounted() {
    var that = this;
    var timer = setInterval(function() {
      var tt = new Date();
      var hh = tt.getHours();
      if (hh<10){
        hh = "0"+hh;
      }
      var mm = tt.getMinutes()
      if (mm<10){
        mm = "0"+mm;
      }
      that.currTime = hh +":"+mm
      console.log('www'+that.currTime );
    }, 3000)

    var timer2 = setTimeout(function() {
      that.$Dialog.Alert('[TIMEOUT TO LOGOUT]','msgpanel');
      that.$router.push("/");
      console.log('logintimeout' );
    }, 45000)
  },
  activated() {},
  destroyed() {},
  methods: {
      clickButton(index){
        if (index!=1){
          // this.$Dialog.Toast('YOU DONT HAVE PERMISSION TO OPERATE','top');
          console.log("xxx"+index)
          this.showMsg();
        }
      },
      clickArea(index){
        if (index!=3){
          // this.$Dialog.Toast('YOU DONT HAVE PERMISSION TO OPERATE','top');
          console.log("xxx"+index)
          this.showMsg();
        }
      },
      showMsg(){
        this.$Dialog.Alert('[YOU DON\'T HAVE PERMISSION TO OPERATE]','msgpanel');
      }
  },
};
</script>

<style scoped>

  .PageBox {
    width: 1500px;
    height: 950px;
    background-color:#1e1e3c;
  }
  .bg2{
    background-color: #0e1526
  }
  .bg3{
    background-color: #1e1e3c;
    color:#5050B4;
  }
  .panel{
    margin: 5px;
  }
  .panel2{
    margin: 5px;
    margin-right: 0px;
  }
  .logo{
    margin-left: 20px;
    margin-top: 5px;
    width: 130px;height: 50px;object-fit: contain
  }
  .area{
    width: 450px;height: 300px;object-fit: contain
  }
  .arrow{
    width: 45px;height: 45px;object-fit: contain
  }
  .buttonitem{
    width: 50px;height: 50px;object-fit: contain;
  }
  .button2{
    width: 35px;height: 35px;object-fit: contain;
    margin-left: 5px;margin-top: 10px;
  }
  .menuitem{
    width: 30px;height: 30px;object-fit: contain;
    margin-right: 15px;
    background-color:transparent
  }
  .msgpanel{
    font-size: 50px;
    background-color: #5050B4;
  }
</style>

